import { Extension } from 'tiptap'

export default class EnterExtension extends Extension {
  keys () {
    return {
      Enter: (state, dispatch, view) => {
        return this.options.editorEnter(state, dispatch, view)
      }
    }
  }
}
