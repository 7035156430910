<template>
  <div>
    <div class="editor" v-bind:class="{'share-proposal' : contentKey === 'shareProposal'}" >

      <editor-floating-menu :class="{'fixed-menu': fixedMenu}" :editor="editor" v-slot="{ commands, isActive, menu }">
        <div
          class="editor__floating-menu"
          :class="{ 'is-active': menu.isActive }"
          :style="`top: ${menu.top}px`"
        >
            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.bold() }"
              @click="commands.bold"
            >
              <mdc-icon icon="format_bold"/>
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.italic() }"
              @click="commands.italic"
            >
              <mdc-icon icon="format_italic"/>
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.strike() }"
              @click="commands.strike"
            >
              <mdc-icon icon="format_strikethrough"/>
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.underline() }"
              @click="commands.underline"
            >
              <mdc-icon icon="format_underline"/>
            </button>

            <button
              v-if="!reducedOptions"
              class="menubar__button"
              :class="{ 'is-active': isActive.todo_list() }"
              @click="commands.todo_list"
            >
              <mdc-icon outlined icon="check_box"/>
            </button>

        </div>
      </editor-floating-menu>

      <editor-menu-bubble v-if="!fixedMenu" :editor="editor" :keep-in-bounds="keepInBounds" v-slot="{ commands, isActive, getMarkAttrs, menu }">
      <div
        class="menububble"
        :class="{ 'is-active': menu.isActive }"
        :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
      >
        <form class="menububble__form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
          <input class="menububble__input" type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu"/>
          <button class="menububble__button" @click="setLinkUrl(commands.link, null)" type="button">
            <mdc-icon icon="clear" />
          </button>
        </form>

        <template v-else>

        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
        >
          <mdc-icon icon="format_bold"/>
        </button>

        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic"
        >
          <mdc-icon icon="format_italic"/>
        </button>

        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.strike() }"
          @click="commands.strike"
        >
          <mdc-icon icon="format_strikethrough"/>
        </button>

        <button
          class="menububble__button"
          :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline"
        >
          <mdc-icon icon="format_underline"/>
        </button>

        <button
          v-if="!reducedOptions"
          class="menububble__button"
          :class="{ 'is-active': isActive.link() }"
          @click="showLinkMenu(getMarkAttrs('link'))"
        >
          <mdc-icon icon="link"/>
        </button>
        </template>
      </div>
    </editor-menu-bubble>

    <editor-content @blur.native="$emit('blur')" @click.native="handleContentClick" class="editor__content" :editor="editor" />

  </div>

  <div class="suggestion-list" :style="{ top: suggestionPos.top, left: suggestionPos.left }" v-show="showSuggestions" ref="suggestions">
      <template v-if="hasResults">
        <div
          v-for="(suggestion, index) in filteredSuggestions"
          :key="suggestion.id"
          class="suggestion-list__item"
          :class="{ 'is-selected': navigatedSuggestionIndex === index }"
          @click="selectOption(suggestion)"
        >
          {{ suggestion.name }}
        </div>
      </template>
      <div v-else class="suggestion-list__item is-empty">
        None found
      </div>
  </div>

  </div>
</template>

<script>
import { Editor, EditorContent, EditorFloatingMenu, EditorMenuBubble } from 'tiptap'
import Hashtag from '../utils/tiptap/Hashtag'
import Profiletag from '../utils/tiptap/Profiletag'
import EnterExtension from '../utils/tiptap/EnterExtension'
import {
  TodoItem,
  TodoList,
  Bold,
  Strike,
  Italic,
  Underline,
  Link,
  History,
  Placeholder,
  Code
} from 'tiptap-extensions'

export default {
  props: {
    content: {
      type: String
    },
    contentKey: {
      type: String
    },
    title: {
      type: String
    },
    focused: {
      type: Boolean,
      default: false
    },
    customCommands: {
    },
    mentionSuggestions: {
    },
    hashtags: {
    },
    emitEnter: {
      type: Boolean,
      default: false
    },
    reducedOptions: {
      type: Boolean,
      default: false
    },
    fixedMenu: {
      type: Boolean,
      default: false
    }
  },
  components: {
    EditorContent,
    EditorFloatingMenu,
    EditorMenuBubble
  },
  data () {
    return {
      linkUrl: null,
      linkMenuIsActive: false,
      keepInBounds: true,
      hashtagActive: false,
      inSuggestion: false,
      suggestionPos: { top: 0, left: 0 },
      editor: new Editor({
        extensions: [
          new EnterExtension({
            editorEnter: (state, dispatch, view) => this.editorEnter(state, dispatch, view)
          }),
          new TodoItem({
            nested: true
          }),
          new Code(),
          new TodoList(),
          new Bold(),
          new Strike(),
          new Italic(),
          new Underline(),
          new Link(),
          new History(),
          new Placeholder({
            emptyNodeClass: 'is-empty'
          }),
          new Hashtag({
            items: () => {
              return this.hashtags
            },
            onEnter: ({
              items, query, range, command, virtualNode
            }) => {
              this.hashtagActive = true
              this.query = query
              this.suggestionRange = range
              this.filteredSuggestions = items
              this.insertHashTag = command
              this.suggestionPos = { top: 'unset', left: 'unset' }
            },
            onChange: ({
              view, items, query, range, virtualNode
            }) => {
              this.hashtagActive = true
              this.filteredSuggestions = items
              this.suggestionRange = range
              this.navigatedSuggestionIndex = 0
              this.suggestionPos = { top: 'unset', left: 'unset' }
            },
            onExit: ({
              view, items, query, range, virtualNode
            }) => {
              if (query && !items) {
                this.insertHashTag({
                  range: this.suggestionRange,
                  attrs: {
                    id: 'new',
                    label: query
                  }
                })
              }
              this.suggestionPos = { top: 'unset', left: 'unset' }
              this.query = null
              this.filteredSuggestions = []
              this.suggestionRange = null
              this.navigatedSuggestionIndex = 0
              this.destroyPopup()
            },
            onKeyDown: ({ event }) => {
              this.hashtagActive = true
              if (event.key === 'ArrowUp') {
                this.upHandler()
                return true
              }
              if (event.key === 'ArrowDown') {
                this.downHandler()
                return true
              }
              if (event.key === 'Enter') {
                this.enterHandler()
                return true
              }
              return false
            },
            onFilter: (items, query) => {
              this.hashtagActive = true
              if (!query) {
                return items
              }
              return items
                .filter(item => JSON.stringify(item.name).toLowerCase().includes(query.toLowerCase()))
            }
          }),
          new Profiletag({
            // a list of all suggested items
            items: () => {
              return this.mentionSuggestions
            },
            // is called when a suggestion starts
            onEnter: ({
              items, query, range, command, virtualNode
            }) => {
              this.hashtagActive = false
              this.query = query
              this.filteredSuggestions = items
              this.suggestionRange = range
              this.renderPopup(virtualNode)
              // we save the command for inserting a selected mention
              // this allows us to call it inside of our custom popup
              // via keyboard navigation and on click
              this.insertMention = command
              this.suggestionPos = { top: 'unset', left: 'unset' }
            },
            // is called when a suggestion has changed
            onChange: ({
              items, query, range, virtualNode
            }) => {
              this.hashtagActive = false
              this.query = query
              this.filteredSuggestions = items
              this.suggestionRange = range
              this.navigatedSuggestionIndex = 0
              this.renderPopup(virtualNode)
              this.suggestionPos = { top: 'unset', left: 'unset' }
            },
            // is called when a suggestion is cancelled
            onExit: () => {
              // reset all saved values
              this.query = null
              this.hashtagActive = false
              this.filteredSuggestions = []
              this.suggestionRange = null
              this.navigatedSuggestionIndex = 0
              this.destroyPopup()
              this.suggestionPos = { top: 'unset', left: 'unset' }
            },
            // is called on every keyDown event while a suggestion is active
            onKeyDown: ({ event }) => {
              this.hashtagActive = false
              if (event.key === 'ArrowUp') {
                this.upHandler()
                return true
              }
              if (event.key === 'ArrowDown') {
                this.downHandler()
                return true
              }
              if (event.key === 'Enter') {
                this.enterHandler()
                return true
              }
              return false
            },
            // is called when a suggestion has changed
            // this function is optional because there is basic filtering built-in
            // you can overwrite it if you prefer your own filtering
            // in this example we use fuse.js with support for fuzzy search
            onFilter: (items, query) => {
              this.hashtagActive = false
              if (!query) {
                return items
              }
              return items
                .filter(item => JSON.stringify(item.name).toLowerCase().includes(query.toLowerCase()))
            }
          })
        ],
        onUpdate: this.onTextUpdate
      }),
      query: null,
      suggestionRange: null,
      filteredSuggestions: [],
      navigatedSuggestionIndex: 0,
      insertMention: () => {},
      insertHashTag: () => {},
      htmlContent: '',
      updateTimer: null
    }
  },
  methods: {
    showLinkMenu (attrs) {
      this.linkUrl = attrs.href
      this.linkMenuIsActive = true
      this.$nextTick(() => {
        this.$refs.linkInput.focus()
      })
    },
    focus () {
      console.log('Tiptap.focus', this.getContent())
      this.$nextTick(() => {
        this.editor.view.dom.focus()
      })
    },
    hideLinkMenu () {
      this.linkUrl = null
      this.linkMenuIsActive = false
    },
    setLinkUrl (command, url) {
      command({ href: url })
      this.hideLinkMenu()
    },
    onTextUpdate ({ getHTML, getJSON, transaction }) {
      if (this.updateTimer) {
        clearTimeout(this.updateTimer)
      }
      const text = getHTML()
      this.htmlContent = text
      if (this.customCommands) {
        for (const command of this.customCommands) {
          if (text.includes(command)) {
            const newText = text.replace(command, '')
            this.htmlContent = newText
            this.editor.setContent(newText, true, { preserveWhitespace: true })
            this.$emit('onCommand', command)
          }
        }
      }
      if (this.inSuggestion) {
        this.inSuggestion = false
        this.filteredSuggestions = []
      }
      const self = this
      this.updateTimer = setTimeout(function () {
        self.$emit('onTextUpdate', text, self.contentKey)
      }, 100)
    },
    getMentions () {
      function findMention (node) {
        let results = []
        // console.log('getMentions', node)
        if (node.type === 'profiletag') {
          return [node.attrs.id]
        } else if (node.content) {
          for (const content of node.content) {
            results = [...results, ...findMention(content)].flat()
          }
        }
        return results
      }
      const json = this.editor.getJSON()
      return findMention(json)
    },
    getTags () {
      function findTag (node) {
        let results = []
        if (node.type === 'hashtag') {
          return [node.attrs.label]
        } else if (node.content) {
          for (const content of node.content) {
            results = [...results, ...findTag(content)].flat()
          }
        }
        return results
      }
      const json = this.editor.getJSON()
      return findTag(json)
    },
    getContent () {
      return this.editor.getHTML()
    },
    editorEnter (state, dispatch, view) {
      if (this.emitEnter) {
        this.$emit('editorEnter', state, dispatch, view)
        return true
      }
      return false
    },
    // navigate to the previous item
    // if it's the first item, navigate to the last one
    upHandler () {
      this.navigatedSuggestionIndex = ((this.navigatedSuggestionIndex + this.filteredSuggestions.length) - 1) % this.filteredSuggestions.length
    },
    // navigate to the next item
    // if it's the last item, navigate to the first one
    downHandler () {
      this.navigatedSuggestionIndex = (this.navigatedSuggestionIndex + 1) % this.filteredSuggestions.length
    },
    enterHandler () {
      if (this.hashtagActive) {
        const tag = this.hashtags[this.navigatedSuggestionIndex]
        if (tag) {
          this.selectHashTag(tag)
        }
      } else {
        const user = this.filteredSuggestions[this.navigatedSuggestionIndex]
        if (user) {
          this.selectSuggestion(user)
        }
      }
    },
    // we have to replace our suggestion text with a mention
    // so it's important to pass also the position of your suggestion text
    selectOption (sugestion) {
      if (this.hashtagActive) {
        this.selectHashTag(sugestion)
      } else {
        this.selectSuggestion(sugestion)
      }
      this.filteredSuggestions = []
    },
    selectSuggestion (suggestion) {
      this.insertMention({
        range: this.suggestionRange,
        attrs: {
          id: suggestion.id,
          label: suggestion.name,
          photoURL: suggestion.photoURL,
          initials: suggestion.initials
        }
      })
      this.focus()
    },
    selectHashTag  (tag) {
      this.insertHashTag({
        range: this.suggestionRange,
        attrs: {
          id: tag.id,
          label: tag.name
        }
      })
      this.focus()
    },
    renderPopup (node) {
    },
    destroyPopup () {
    },
    handleContentClick (ev) {
      if (ev.target.matches('.profiletag')) {
        this.hashtagActive = false
        this.query = ''
        this.filteredSuggestions = this.mentionSuggestions
        this.suggestionRange = this.editor.selection
        this.navigatedSuggestionIndex = this.mentionSuggestions.findIndex(suggestion => suggestion.name === ev.target.outerText)
        this.suggestionPos = { top: `${ev.clientY + 10}px`, left: `${ev.clientX}px` }
        this.inSuggestion = true
      } else {
        if (this.inSuggestion) {
          this.inSuggestion = false
          this.filteredSuggestions = []
        }
      }
    }
  },
  computed: {
    hasResults () {
      return this.filteredSuggestions.length
    },
    showSuggestions () {
      return this.query || this.hasResults
    }
  },
  mounted () {
    if (this.focused) {
      this.focus()
    }
    if (this.content) {
      this.htmlContent = this.content
      this.editor.setContent(this.content, true, { preserveWhitespace: true })
    } else {
      this.editor.setContent('')
    }
  },
  watch: {
    content: function (newC, oldC) {
      if (this.content) {
        // console.log('Tiptap.watch: content', this.htmlContent, this.content)
        if (this.htmlContent !== this.content) {
          this.editor.setContent(this.content, true, { preserveWhitespace: true })
        }
      } else {
        this.editor.setContent('')
      }
    }
  },
  beforeDestroy () {
    this.destroyPopup()
  }
}
</script>

<style>
.editor .menububble {
  background:white !important;
}
.editor .menububble.is-active {
  opacity: 1;
  visibility: visible;
  background-color:rgba(255,255,255,0.95) !important;
  padding: 4px;
  border-radius: 4px;
  margin-top:8px;
}

.editor .menububble__input {
    font: inherit;
    border: none;
    background-color:rgba(255,255,255,0.95) !important;
    color: #333333 !important;
    padding: 4px;
}

.update-input .editor .ProseMirror a {
  color:black;
  cursor:pointer;
}
.update-input .editor .ProseMirror a:hover {
  background-color:#EFEFEF;
}

.menubar__button, .menububble__button {
  color:#999999 !important;
  font-size:16px !important;
  margin-top:6px;
  padding-top:0 !important;
  padding-bottom:0 !important;
  padding-left:2px !important;
  padding-right:2px !important;
  background-color:white;

}

.menububble__button.is-active {
    background-color: rgba(0, 0, 0, 0.1) !important;
}
.menububble__button:hover {
    background-color: rgba(0, 0, 0, 0.05) !important;
}

.editor__floating-menu {
  transition: none !important;
}

.editor__floating-menu.is-active {
  background:transparent;
  padding: 4px;
  border-radius: 4px;
  margin-top: -0.8rem !important;
  margin-left: 6px;
  color:#999999;
}

.suggestion-list {
    position: fixed;
    z-index: 99;
    color: #333;
    padding: 0px;
    border-radius: 6px;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0,0,0,.12);
}
.suggestion-list .suggestion-list__item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.suggestion-list .suggestion-list__item:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.suggestion-list .suggestion-list__item {
  padding:6px;
  background-color: white;
  cursor:pointer;
}
.suggestion-list .suggestion-list__item:hover {
  background-color: #DDD;
}
.suggestion-list .suggestion-list__item.is-selected {
  background-color: #CCC
}
.editor p.is-empty:first-child::before {
  content: 'Add note...';
  float: left;
  color: #aaa;
  pointer-events: none;
  height: 0;
  font-style: italic;
}
.editor:focus-within p.is-empty:first-child::before {
  content: '';
}
.editor s * {
  text-decoration: line-through;
}
.editor u s * {
  text-decoration: underline line-through;
  border-bottom: 1px solid;
}
.editor s u * {
  text-decoration: underline line-through;
}

.editor.share-proposal p.is-empty:first-child::before {
  content: 'Add Cover Letter text here';
}

ul[data-type="todo_list"] {
  padding-left: 0;
}

li[data-type="todo_item"] {
  display: flex;
  flex-direction: row;
}

.todo-checkbox {
  border: 2px solid black;
  height: 0.9em;
  width: 0.9em;
  box-sizing: border-box;
  margin-right: 10px;
  margin-top: 0.3rem;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  border-radius: 0.2em;
  background-color: transparent;
  transition: 0.4s background;
}

.todo-content {
  flex: 1;
}

.todo-content p {
  margin:2px;
}

ul[data-type="todo_list"] li[data-done="true"] {
  text-decoration: line-through;
}

ul[data-type="todo_list"] li[data-done="true"] .todo-checkbox {
  background-color: black;
}

ul[data-type="todo_list"] li[data-done="false"] {
  text-decoration: none;
}

.profiletag .avatar {
    height: 22px;
    width: 22px;
    position: absolute;
    margin-left: -24px;
    margin-top: -4px;
}
.profiletag  {
    margin-left:4px;
    padding-bottom: 1px;
    padding-left: 24px;
    padding-right: 6px;
    height: 16px;
    line-height: 14px;
    font-size: 14px;
    letter-spacing: 0.3px;
    border-radius: 16px;
    text-decoration: inherit;
    overflow: visible;
}
.profiletag.suggestion  {
  padding-left: 6px;
  height: 16px;
  line-height:14px;
  font-size: 14px;
}

.profiletag.ProseMirror-selectednode {
  background-color: var(--mdc-theme-primary, #6200ee) !important;
  color: white;
}

</style>

<style lang="scss" scoped>
$color-black: #000000;
$color-white: #ffffff;

.icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 0.8rem;
  height: 0.8rem;
  margin: 0 0rem;
  fill: currentColor;
}

.editor {
  position: relative;

  &__floating-menu {
    position: absolute;
    z-index: 1;
    margin-top: -0.25rem;
    visibility: hidden;
    opacity: 0;
    &.is-active {
      opacity: 1;
      visibility: visible;
    }
  }

}

.editor {
  position: relative;
  margin: 0;

  &__content {
    pre {
      padding: 0.7rem 1rem;
      border-radius: 5px;
      background: $color-black;
      color: $color-white;
      font-size: 0.8rem;
      overflow-x: auto;

      code {
        display: block;
      }
    }

    p code {
      display: inline-block;
      padding: 0 0.4rem;
      border-radius: 5px;
      font-size: 0.8rem;
      font-weight: bold;
      background: rgba($color-black, 0.1);
      color: rgba($color-black, 0.8);
    }

    ul,
    ol {
      padding-left: 1rem;
    }

    a {
      color: inherit;
    }

    blockquote {
      border-left: 3px solid rgba($color-black, 0.1);
      color: rgba($color-black, 0.8);
      padding-left: 0.8rem;
      font-style: italic;

      p {
        margin: 0;
      }
    }

    img {
      max-width: 100%;
      border-radius: 3px;
    }

  }
}

.menububble {
  position: absolute;
  display: flex;
  z-index: 20;
  background: $color-black;
  border-radius: 5px;
  padding: 0.3rem;
  margin-bottom: 0.5rem;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;

  &__button {
    display: inline-flex;
    background: transparent;
    border: 0;
    color: $color-white;
    padding: 0.2rem 0.5rem;
    margin-right: 0.2rem;
    border-radius: 3px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: rgba($color-white, 0.1);
    }

    &.is-active {
      background-color: rgba($color-white, 0.2);
    }
  }

  &__form {
    display: flex;
    align-items: center;
  }

  &__input {
    font: inherit;
    border: none;
    background: transparent;
    color: $color-white;
  }
}

.fixed-menu {
  position: relative;
  z-index: 1;
  margin-top: -0.25rem;
  visibility: visible !important;
  opacity: 1 !important;
  left: -10px;
  top: 5px !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
  padding: 0px !important;
}

.menubar {

  display: flex;
  transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;
  position:relative;
  top:13px;

  &.is-hidden {
    visibility: hidden;
    opacity: 0;
  }

  &.is-focused {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.2s, opacity 0.2s;
  }

  &__button {
    font-weight: bold;
    display: inline-flex;
    background: transparent;
    border: 0;
    color: $color-black;
    padding: 0.2rem 0.5rem;
    margin-right: 0.2rem;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background-color: rgba($color-black, 0.05);
    }

    &.is-active {
      background-color: rgba($color-black, 0.1);
    }
  }
}

</style>
