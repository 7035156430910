import { Mention as TipTapMention } from 'tiptap-extensions'

export default class Hashtag extends TipTapMention {
  get name () {
    return 'hashtag'
  }

  get defaultOptions () {
    return {
      matcher: {
        char: '#',
        allowSpaces: false,
        startOfLine: false
      },
      mentionClass: 'hashtag',
      suggestionClass: 'hashtag suggestion'
    }
  }

  get schema () {
    return {
      ...super.schema,
      selectable: true,
      draggable: true,
      toDOM: (node) => {
        // use a dummy domain because URL cannot handle relative urls

        return [
          'span',
          {
            class: this.options.mentionClass + ' hashtag',
            'data-hashtag-id': node.attrs.id
          },
          ['img', { class: 'avatar', src: '/static/img/teams/initative.svg' }],
          `${node.attrs.label}`
        ]
      },
      parseDOM: [
        {
          tag: 'span[data-hashtag-id]',
          getAttrs: (dom) => {
            const id = dom.getAttribute('data-hashtag-id')
            const label = dom.innerText.split(this.options.matcher.char).join('')
            return { id, label }
          }
        }
      ]
    }
  }
}
