import { Mention as TipTapMention } from 'tiptap-extensions'

export default class Profiletag extends TipTapMention {
  get name () {
    return 'profiletag'
  }

  get defaultOptions () {
    return {
      matcher: {
        char: '@',
        allowSpaces: false,
        startOfLine: false
      },
      mentionClass: 'mention profiletag',
      suggestionClass: 'mention profiletag suggestion'
    }
  }

  get schema () {
    return {
      ...super.schema,
      attrs: {
        id: {},
        label: {},
        photoURL: {},
        initials: {}
      },
      selectable: true,
      draggable: true,
      toDOM: (node) => {
        return [
          'span',
          {
            class: this.options.mentionClass,
            'data-mention-id': node.attrs.id,
            'data-photo-url': node.attrs.photoURL,
            'data-initials': node.attrs.initials
          },
          node.attrs.photoURL ? ['img', { class: 'avatar', src: node.attrs.photoURL }] : ['span', { class: 'default-avatar', url: node.attrs.initials }],
          `${node.attrs.label}`
        ]
      },
      parseDOM: [
        {
          tag: 'span[data-mention-id]',
          getAttrs: (dom) => {
            const id = dom.getAttribute('data-mention-id')
            const label = dom.innerText.split(this.options.matcher.char).join('')
            const photoURL = dom.getAttribute('data-photo-url')
            const initials = dom.getAttribute('data-initials')
            return { id, label, photoURL, initials }
          }
        }
      ]
    }
  }
}
